'use strict';

var base = require('core/product/base');


base.updateSetItemsAvailability = function () {
    var scope = this;

    $(document).off('change', '.product-set-quantity-select');
    $(document).on('change', '.product-data .product-set-quantity-select', function (e) {
        e.preventDefault();

        var $productContainer = $(this).closest('.product-set-detail').find('.set-items');
        if (!$productContainer.length) {
            $productContainer = $(this).closest('.modal-content').find('.set-items');
        }
        var $quantitySelected = $(e.currentTarget).find('option:selected').val();

        $productContainer.find('.product-set-item-detail').each(function () {
            var $setItemQuantity = $(this).find('.product-set-quantity-select');
            var qtyStepper = $(this).find('.quantity-stepper');
            $setItemQuantity.val($quantitySelected).find('option').each(function () {
                if (this.value == $quantitySelected) {
                    $(this).prop('selected','selected');
                    $setItemQuantity.change();
                    if (qtyStepper.length) {
                        qtyStepper.find('input').val($quantitySelected).attr('data-qty',$quantitySelected);
                        scope.methods.updateQuantityStepperDisabledStates(qtyStepper);
                    }
                    return false;
                }
            })
        });
    });
}

module.exports = base;
